<template>
    <div class="app-container monthlycardapplication">
        <div class="filter-line">
            <span>
                <el-button @click="exportHandler">{{ this.$t("commons.export") }}</el-button>
                <el-button icon="el-icon-refresh-right" @click="getTableList">{{ this.$t("commons.refresh") }}</el-button>
            </span>
            <div class="header-filter-button" @click="dataset.filterLine.show = !dataset.filterLine.show"
                :class="dataset.filterLine.show ? 'filter-button-open' : 'filter-button-close'">
                <i class="iconfont icon-loudoutu"></i>筛选
            </div>
        </div>
        <div>
            <finalTable ref="finalTableRef" :datas="dataset" @tableEventHandler="tableEventHandler"></finalTable>
        </div>
    </div>
</template>
<script>
import finalTable from "@/components/FinalTable";
import { dateFormat } from "@/filters/index";
import {
    getCardList,
    MonthlyCardExport
} from "@/api/ruge/gsPark/customerService/monthlyCardApplication";
export default {
    name: "monthlycardapplication",
    components: {
        finalTable,
    },
    data() {
        return {
            dataset: {
                paginationConfig: {
                    need: true,
                },
                filterLine: {
                    show: true,
                },
                header: [
                    { prop: "applicationTime", label: "申请时间", width: "" },
                    { prop: "plate", label: "车牌号", width: "" },
                    // { prop: "company", label: "所属企业", width: "" },
                    { prop: "areaCode", label: "所属停车场", width: "" },
                    { prop: "userName", label: "申请人", width: "" },
                    { prop: "applicationNum", label: "申请ID", width: "" },
                    { prop: "phone", label: "联系电话", width: "" },
                    { prop: "startTime", label: "首月开始日期", width: "" },
                    { prop: "payMoney", label: "首月费用", width: "" },
                    { prop: "status", label: "状态", width: "" },
                    { prop: "operation", label: "操作", width: "200" },
                ],
                tableData: [],
                // 搜索行配置
                searchLineConfig: {
                    plate: {
                        type: "input",
                        label: "车牌号",
                        actionType: "goSearch",
                        multiple: true,
                        collapseTags: true,
                        prefixIcon: "el-icon-search",
                    },
                    // company: {
                    //     type: "input",
                    //     label: "所属企业",
                    //     actionType: "goSearch",
                    //     multiple: true,
                    //     collapseTags: true,
                    //     prefixIcon: "el-icon-search",
                    // },
                    areaCode: {
                        type: "selector",
                        label: "状态",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入状态",
                        optionList: [
                            {
                                label: "锦绣园区停车场（一、三期）",
                                value: "1",
                            },
                            {
                                label: "华萃公寓停车场（二期）",
                                value: "2",
                            },
                        ]
                    },
                    userName: {
                        type: "input",
                        label: "申请人",
                        actionType: "goSearch",
                        multiple: true,
                        collapseTags: true,
                        prefixIcon: "el-icon-search",
                    },
                    applicationNum: {
                        type: "input",
                        label: "申请ID",
                        actionType: "goSearch",
                        multiple: true,
                        collapseTags: true,
                        prefixIcon: "el-icon-search",
                    },
                    phone: {
                        type: "input",
                        label: "联系电话",
                        actionType: "goSearch",
                        multiple: true,
                        collapseTags: true,
                        prefixIcon: "el-icon-search",
                    },
                    payMoney: {
                        type: "input",
                        label: "首月费用",
                        actionType: "goSearch",
                        multiple: true,
                        collapseTags: true,
                        prefixIcon: "el-icon-search",
                    },
                    status: {
                        type: "selector",
                        label: "状态",
                        value: "",
                        actionType: "goSearch",
                        placeholder: "请输入状态",
                        optionList: [
                            {
                                label: "待审核",
                                value: "1",
                            },
                            {
                                label: "已开通",
                                value: "4",
                            },
                            {
                                label: "已通过",
                                value: "3",
                            },
                            {
                                label: "已取消",
                                value: "0",
                            },
                            {
                                label: "已驳回",
                                value: "2",
                            },
                        ]
                    },
                    operation: {
                        type: "button",
                        filterCount: "",
                        actionType: "clickEvent",
                        eventName: "showMoreFilters",
                        label: "更多筛选",
                    },
                },
                // 表格内容配置
                detailConfig: {
                    applicationTime: {
                        type: "dateFormat", // 将格式改成YYYY-MM-DD HH:mm:ss
                    },
                    operation: {
                        type: "icon",
                        iconList: [
                            {
                                actionType: "iconClick",
                                eventName: "view",
                                fontSize: "14px",
                                color: "#1A4CEC",
                                cursorPointer: "pointer",
                                iconName: "el-icon-view",
                                tooltips: "查看",
                            },
                        ],
                    },
                },
                // 高级搜索配置
                advanceFilterConfig: {
                    applicationTime: {
                        type: "dateRange",
                        pickerType: "date",
                        label: "申请时间",
                        value: [],
                    },
                    startTime: {
                        type: "dateRange",
                        pickerType: "date",
                        label: "首月开始日期",
                        value: [],
                    },
                },
                pageVO: {
                    current: 1,
                    rowCount: 10,
                    total: 0,
                },
            },
            MonthlyCardObj: {
                tableLoading: false,
                listQuery: {
                    current: 1,
                    rowCount: 10,
                    plate: null,
                    company: null,
                    phone: null,
                    startTime: null,
                    applicationTime: null,
                    status: null,
                    MonStartTime: null,
                    MonEndTime: null,
                    userName: null,
                    payMoney: null,
                    AppStartTime: null,
                    AppEndTime: null,
                }
            }
        }
    },
    created() {
        this.getTableList()
    },
    methods: {
        // 初始化数据
        getTableList() {
            this.MonthlyCardObj.tableLoading = true;
            const params = { ...this.MonthlyCardObj.listQuery };
            for (let pn in params) {
                !params[pn] && params[pn] !== 0 && (params[pn] = null);
            }
            getCardList(params)
                .then((res) => {
                    console.log(res, '列表数据');
                    res.data.rows.forEach((item) => {
                        item.areaCode = item.areaCode === 1 ? "锦绣园区停车场（一、三期）" : "华萃公寓停车场（二期）";
                    });
                    this.dataset.tableData = res.data.rows;
                    this.dataset.pageVO.total = res.data.total;
                    this.dataset.tableData = res.data.rows;
                    // this.dataset.tableData[0].invoiceBillNo = 'RXFP20230530115011892921'
                    console.log(this.dataset.tableData, 'this.dataset.tableData');
                })
                .finally(() => {
                    this.MonthlyCardObj.tableLoading = false;
                });
        },
        // table组件页面事件配置
        tableEventHandler(datas) {
            if (datas.type === "goSearch") {
                this.dealTime(datas)
                this.dealTimeFirstMonth(datas)
                this.MonthlyCardObj.listQuery = { ...this.MonthlyCardObj.listQuery, ...datas.params };
                this.MonthlyCardObj.listQuery.current = 1;
                this.dataset.pageVO.current = 1;
                this.getTableList();
            } else if (datas.type === "iconClick") {
                switch (datas.eventName) {
                    case "view":
                        this.tbRowDetail(datas.row);
                        break;
                }
            } else if (datas.type === "paginationChange") {
                // 配置分页
                this.MonthlyCardObj.listQuery.current = datas.params.current.page;
                this.MonthlyCardObj.listQuery.rowCount = datas.params.current.limit;
                this.getTableList();
            }
        },
        // 申请时间查询
        dealTime(datas) {
            if (datas.params.applicationTime && datas.params.applicationTime.length > 0) {
                this.MonthlyCardObj.listQuery.AppStartTime = datas.params.applicationTime[0]
                    ? dateFormat(datas.params.applicationTime[0], "YYYY-MM-DD")
                    : null;
                this.MonthlyCardObj.listQuery.AppEndTime = datas.params.applicationTime[1]
                    ? dateFormat(
                        new Date(datas.params.applicationTime[1]).getTime() +
                        1000 * 60 * 60 * 24 - 1,
                        "YYYY-MM-DD"
                    )
                    : null;
                delete datas.params.applicationTime;
            }
            return datas;
        },
        // 首月开始日期查询
        dealTimeFirstMonth(datas) {
            if (datas.params.startTime && datas.params.startTime.length > 0) {
                this.MonthlyCardObj.listQuery.MonStartTime = datas.params.startTime[0]
                    ? dateFormat(datas.params.startTime[0], "YYYY-MM-DD")
                    : null;
                this.MonthlyCardObj.listQuery.AppEndTime = datas.params.startTime[1]
                    ? dateFormat(
                        new Date(datas.params.startTime[1]).getTime() +
                        1000 * 60 * 60 * 24 - 1,
                        "YYYY-MM-DD"
                    )
                    : null;
                delete datas.params.startTime;
            }
            return datas;
        },
        // 查看详情
        tbRowDetail(row) {
            console.log(row);
            let params = {
                path: '/gsPark/parkAffairs/monthlyCardApplication/detail',
                query: {
                    isLook: true,
                    dataId: row.id,
                }
            }
            this.$router.push(params)
        },
        // 导出
        exportHandler() {
            MonthlyCardExport(this.MonthlyCardObj.listQuery).then((taskId) => {
                this.$message({
                    type: "success",
                    message: this.$t("message.operationSuccess"),
                });
                let exportObj = {
                    taskId,
                    taskName: "停车月卡申请",
                    taskStatus: 0,
                    rootPath: "customerGSPath",
                };
                this.$store.dispatch("PushExportNotice", exportObj);
            });
        },
    },
    watch: {
        MonthlyCardObj: {
            handler(val) {
                this.$refs.finalTableRef.loadingToggle(val.tableLoading);
            },
            deep: true,
        },
    }
}
</script>
<style lang="less" scoped>
.monthlycardapplication {
    .filter-line {
        margin-bottom: 18px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        button {
            height: 36px;
        }

        .icon-loudoutu {
            font-size: 12px;
        }

        .header-filter-button {
            cursor: pointer;
            width: 84px;
            height: 36px;
            line-height: 36px;
            text-align: center;
            border-radius: 3px;
            font-size: 14px;
            font-family: PingFangSC-Regular, PingFang SC;
            font-weight: 400;
        }

        .filter-button-open {
            color: #409eff;
            background: #ecf5ff;
            border: 1px solid #c6e2ff;
        }

        .filter-button-close {
            color: #2a4158;
            background: #ffffff;
            border: 1px solid #e7e8eb;
        }
    }
}
</style>